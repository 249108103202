import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [38,[2]],
		"/admin": [46,[3]],
		"/admin/adiante/[id]/edit": [47,[3]],
		"/admin/admin_users/[id]/edit": [48,[3]],
		"/admin/billing-previews": [49,[3]],
		"/admin/cceal-ccee-energy-amounts": [50,[3]],
		"/admin/ccee-contracts": [51,[3]],
		"/admin/ccee-contracts/[slug]": [52,[3,4]],
		"/admin/ccee-settlements": [53,[3]],
		"/admin/cdes": [54,[3]],
		"/admin/companies": [55,[3]],
		"/admin/companies/new": [76,[3]],
		"/admin/companies/[id]": [56,[3,5]],
		"/admin/companies/[id]/consumer-units": [57,[3,5,6]],
		"/admin/companies/[id]/consumer-units/new": [67,[3,5,6]],
		"/admin/companies/[id]/consumer-units/[slug]": [58,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/edit": [59,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/fattureweb-distributor-credentials/edit": [60,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/financial-obligations/new": [61,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/migration-tasks": [62,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings": [63,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/new": [65,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/[monthlySavingId]/edit": [64,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/viability-studies/new": [66,[3,5,6,7]],
		"/admin/companies/[id]/contracts/new": [68,[3,5]],
		"/admin/companies/[id]/edit": [69,[3,5]],
		"/admin/companies/[id]/financial-obligations/new": [70,[3,5]],
		"/admin/companies/[id]/managerial-reports": [71,[3,5]],
		"/admin/companies/[id]/proposals/new": [72,[3,5]],
		"/admin/companies/[id]/saving-reports": [73,[3,5]],
		"/admin/companies/[id]/saving-reports/new": [74,[3,5]],
		"/admin/companies/[id]/users": [75,[3,5,8]],
		"/admin/contract-terms/[id]/edit": [77,[3,9]],
		"/admin/contract-thresholds": [78,[3]],
		"/admin/contract-thresholds/[contractId]/[referenceMonth]": [79,[3,10]],
		"/admin/contracts": [80,[3]],
		"/admin/contracts/[id]": [81,[3,11]],
		"/admin/contracts/[id]/edit": [82,[3,11]],
		"/admin/contracts/[id]/terms/new": [83,[3,11]],
		"/admin/devecs": [84,[3]],
		"/admin/distributor-companies": [85,[3]],
		"/admin/distributor-companies/[id]": [86,[3,12]],
		"/admin/distributor-companies/[id]/edit": [87,[3,12]],
		"/admin/fattureweb-interactions": [88,[3]],
		"/admin/fattureweb-interactions/[id]": [89,[3,13]],
		"/admin/financial-calendar-events": [90,[3]],
		"/admin/financial-obligations": [91,[3]],
		"/admin/financial-obligations/new": [94,[3]],
		"/admin/financial-obligations/[id]": [92,[3,14]],
		"/admin/financial-obligations/[id]/edit": [93,[3,14]],
		"/admin/hourly-average-co2-emission-factors": [95,[3]],
		"/admin/hourly-plds": [96,[3]],
		"/admin/imports": [97,[3]],
		"/admin/imports/new": [99,[3]],
		"/admin/imports/[id]": [98,[3,15]],
		"/admin/ipca-indices": [100,[3]],
		"/admin/leads": [101,[3]],
		"/admin/login": [102,[3,16]],
		"/admin/login/password-reset/new": [104,[3,16]],
		"/admin/login/password-reset/[token]": [103,[3,16]],
		"/admin/logout": [105,[3]],
		"/admin/mail-recipients/[id]/mail-subscriptions": [106,[3,17]],
		"/admin/measurements": [107,[3]],
		"/admin/monthly-average-co2-emission-factors": [108,[3]],
		"/admin/monthly-consumption": [109,[3,18]],
		"/admin/monthly-plds": [110,[3]],
		"/admin/operations": [111,[3]],
		"/admin/operations/new": [114,[3]],
		"/admin/operations/[id]": [112,[3,19]],
		"/admin/operations/[id]/edit": [113,[3,19]],
		"/admin/optimal-demand-simulations/new": [115,[3]],
		"/admin/optimal-tariff-modality-simulations/new": [116,[3]],
		"/admin/partners": [117,[3]],
		"/admin/proinfa-ccee-energy-amounts": [118,[3]],
		"/admin/proposals": [119,[3]],
		"/admin/proposals/[id]": [120,[3,20]],
		"/admin/renewable-energy-certificates": [121,[3]],
		"/admin/sendgrid-events": [122,[3]],
		"/admin/sendgrid-events/[id]": [123,[3,21]],
		"/admin/state-taxes": [124,[3]],
		"/admin/status-aggregated-measurements": [125,[3]],
		"/admin/tariff-flags-terms": [128,[3]],
		"/admin/tariff-flags": [126,[3]],
		"/admin/tariff-flags/new": [127,[3]],
		"/admin/unauthorized": [129,[3]],
		"/admin/users": [130,[3]],
		"/admin/users/[id]": [131,[3,22]],
		"/admin/users/[id]/accesses": [132,[3,22]],
		"/admin/viability-studies": [133,[3]],
		"/admin/viability-studies/new": [137,[3]],
		"/admin/viability-studies/[id]": [134,[3,23]],
		"/admin/viability-studies/[id]/document": [135],
		"/admin/viability-studies/[id]/new": [136,[3,23]],
		"/agrishow": [138],
		"/cadastro/continuar/[token]/[userId]": [139,[24]],
		"/cadastro/empresa/completo": [140,[24,25]],
		"/cadastro/empresa/editar": [141,[24,25]],
		"/cadastro/empresa/endereco/editar": [142,[24,25]],
		"/cadastro/empresa/representantes": [143,[24,25]],
		"/cadastro/empresa/representantes/novo": [145,[24,25]],
		"/cadastro/empresa/representantes/[id]/editar": [144,[24,25]],
		"/cadastro/empresa/unidades-consumidoras": [146,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/nova": [149,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/editar": [147,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/endereco/editar": [148,[24,25]],
		"/cadastro/empresa/usuarios": [150,[24,25]],
		"/cadastro/empresa/usuarios/novo": [151,[24,25]],
		"/cadastro/obrigado": [152,[24]],
		"/cadastro/uc/fatura/email": [153,[24]],
		"/cadastro/uc/fatura/nova": [154,[24]],
		"/cadastro/uc/informe-de-dados": [155,[24]],
		"/cadastro/uc/nova": [156,[24]],
		"/cadastro/uc/unidades": [157,[24]],
		"/cadastro/usuario/novo": [158,[24]],
		"/(site)/contato": [39,[2]],
		"/empresa-b": [~159],
		"/(site)/mercado-livre-de-energia": [40,[2]],
		"/painel/(auth)/login": [160,[26,27]],
		"/painel/logout": [174,[26]],
		"/painel/(auth)/senha/definida": [161,[26,27]],
		"/painel/(auth)/senha/nova": [162,[26,27]],
		"/painel/(auth)/senha/nova/[token]": [163,[26,27]],
		"/painel/[companyId]": [164,[26,28]],
		"/painel/[companyId]/bi": [165,[26,28,29]],
		"/painel/[companyId]/consumo": [166,[26,28,30]],
		"/painel/[companyId]/economia": [167,[26,28,31]],
		"/painel/[companyId]/mercado-livre": [168,[26,28,32]],
		"/painel/[companyId]/meus-dados": [169,[26,28,33]],
		"/painel/[companyId]/migracao": [170,[26,28,34]],
		"/painel/[companyId]/minha-empresa": [171,[26,28,35]],
		"/painel/[companyId]/pagamentos": [172,[26,28,36]],
		"/painel/[companyId]/sustentabilidade": [173,[26,28,37]],
		"/(site)/parcerias": [41,[2]],
		"/(site)/sobre-nos": [42,[2]],
		"/(site)/solucoes": [43,[2]],
		"/(site)/sustentabilidade": [44,[2]],
		"/(site)/termo-de-adesao": [~45,[2]],
		"/w": [175]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';